<template>
  <div class="flex-1 px-8 py-2 overflow-y-auto mb-14">
    <ul
      role="list"
      :class="isCompressedView ? 'xl:grid-cols-4' : 'xl:grid-cols-6'"
      class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:gap-x-4"
    >
      <li
        v-for="asset in visibleAssets"
        :key="asset.name"
        class="relative"
      >
        <GridItem
          :key="asset.id"
          :asset="asset"
          :assets="assets"
          :active-folder="activeFolder"
          :can-preview="canPreview"
          :can-select="canSelect"
          :can-replace="canReplace"
          :can-delete="canDelete"
          :can-rename="canRename"
          :dragger="dragger"
          :display-mode="displayMode"
          :action-mode="actionMode"
          :is-selected="isSelectedAsset(asset)"
          :is-focused="isFocusedAsset(asset)"
          :is-multiselected="isMultiselectedAsset(asset)"
          @multiselect-asset="$emit('multiselect-asset', $event)"
          @multideselect-asset="$emit('multideselect-asset', $event)"
          @delete-asset="$emit('delete-asset', $event)"
          @preview-asset="$emit('preview-asset', $event)"
          @rename-asset="$emit('rename-asset', $event)"
          @replace-asset="$emit('replace-asset', $event)"
          @deselect-asset="$emit('deselect-asset', $event)"
          @drag-asset="$emit('drag-asset', $event)"
          @drag-asset-focus="$emit('drag-asset-focus', $event)"
          @drop-asset="$emit('drop-asset', $event)"
          @select-asset="$emit('select-asset', $event)"
          @view-asset="$emit('view-asset', $event)"
          @move-asset="$emit('move-asset', $event)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import layout from './assets-explorer-collection-layout-mixin.js'
const GridItem = () => import('./assets-explorer-collection-grid-item.vue')
const HiddenBlock = () => import('./assets-explorer-collection-hidden-block.vue')
const EmptyCollectionBlock = () => import('./assets-explorer-collection-empty-block.vue')

export default {
  name: 'AssetsExplorerCollectionGridLayout',

  components: {
    GridItem,
    EmptyCollectionBlock,
    HiddenBlock
  },

  mixins: [layout],

  props: {
    asset: Object,
    selected: Boolean,
    infoSidebarOpen: Boolean,
    folderSidebarOpen: Boolean
  },

  emits: ['select'],

  computed: {
    isCompressedView() {
      return this.infoSidebarOpen && this.folderSidebarOpen
    }
  },

  methods: {
    isSelected(asset) {
      return (this.selected.find(row => row.id === asset.id) !== undefined)
    }
  }
}
</script>
